import GATSBY_COMPILED_MDX from "/home/runner/work/discretize.eu/discretize.eu/discretize-guides/builds/mesmer/heal-chronomancer/index.md";
import {APILanguageProvider} from '@discretize/gw2-ui-new';
import DescriptionIcon from '@mui/icons-material/Description';
import {graphql} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from 'tss-react/mui';
import Template from '.';
import ArchiveWarning from '../components/ArchiveWarning';
import {Specialization} from '../components/gw2components';
import GuideHeader from '../components/navigation/GuideHeader';
import BuildOptions from '../components/pages/build/BuildOptions';
import Frontmatter from '../components/pages/build/Frontmatter';
import {getLanguage} from '../state';
import ThumbnailContext from '../state/context/ThumbnailContext';
import {withGw2Theme} from '../utils/withGw2Theme';
import '@discretize/react-discretize-components/dist/index.css';
import '../utils/rdc.css';
import {getCurrentPatch} from '../utils/patches';
const useStyles = makeStyles()(() => ({
  icon: {
    fontSize: 18
  }
}));
const createDefaultDescription = ({title, profession, specialization, role}) => `A detailed guide on the ${title} in Guild Wars 2.${specialization && role ? ` This ${specialization} build is a ${role.toLowerCase()} variant${specialization !== profession ? ` of the ${profession}` : ''}.` : ''}`;
function CMGuideButton({className}) {
  return React.createElement(React.Fragment, null, React.createElement(DescriptionIcon, {
    className: className
  }), " CM guides");
}
const ThemedMarkdown = withGw2Theme()(React.memo(({markdown}) => markdown));
function BuildTemplate({location, children, data: {image: {childImageSharp: image}, guide: {fields: {slug, timeToRead}, frontmatter: {title, date, profession, skills, conditions, archive, hasBeginner = false, specialization, role, author, cmGuide, description: customDescription, code, classification, boons}}}}) {
  const {classes} = useStyles();
  const language = useSelector(getLanguage);
  const description = customDescription || createDefaultDescription({
    title,
    profession,
    specialization,
    author,
    role
  });
  const imagePath = image && image.gatsbyImageData && image.gatsbyImageData.images.fallback.src || null;
  const renderHeader = () => React.createElement(GuideHeader, {
    title: archive ? `ARCHIVED - ${title}` : title,
    subTitle: `${role} Build`,
    icon: React.createElement(Specialization, {
      disableText: true,
      name: specialization,
      size: "big"
    }),
    date: date.trim(),
    author: author,
    timeToRead: timeToRead,
    link: slug,
    profession: profession
  });
  const renderFrontmatter = () => {
    return React.createElement(React.Fragment, null, React.createElement(Frontmatter, {
      boons: boons,
      skills: skills,
      conditions: conditions,
      code: code,
      title: title,
      profession: profession,
      classification: classification,
      role: role,
      date: date.trim()
    }), archive && React.createElement(ArchiveWarning));
  };
  const wrapMarkdown = markdown => React.createElement(ThumbnailContext.Provider, {
    value: image.gatsbyImageData
  }, React.createElement(BuildOptions, {
    hasBeginner: hasBeginner,
    title: title,
    profession: profession,
    buttons: [...cmGuide ? [{
      link: slug.replace('build', 'cm-guide') + cmGuide,
      content: () => React.createElement(CMGuideButton, {
        className: classes.icon
      })
    }] : []]
  }), React.createElement(ThemedMarkdown, {
    profession: profession,
    markdown: markdown
  }));
  return React.createElement(APILanguageProvider, {
    value: language
  }, React.createElement(Template, {
    location: location,
    SeoProps: {
      title,
      description,
      imagePath
    },
    markdown: children,
    renderHeader: renderHeader,
    renderFrontmatter: renderFrontmatter,
    wrapMarkdown: wrapMarkdown
  }));
}
BuildTemplate.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.object,
  data: PropTypes.shape({
    image: PropTypes.shape({
      childImageSharp: PropTypes.object
    }),
    guide: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string,
        timeToRead: PropTypes.number
      }),
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        date: PropTypes.string,
        profession: PropTypes.string,
        specialization: PropTypes.string,
        role: PropTypes.string,
        author: PropTypes.string,
        cmGuide: PropTypes.string,
        archive: PropTypes.bool,
        hasBeginner: PropTypes.bool,
        description: PropTypes.string,
        boons: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string,
          variant: PropTypes.string,
          uptime: PropTypes.string
        })),
        code: PropTypes.string,
        skills: PropTypes.arrayOf(PropTypes.number),
        conditions: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string,
          variant: PropTypes.string,
          uptime: PropTypes.string
        })),
        classification: PropTypes.arrayOf(PropTypes.number)
      })
    })
  }).isRequired
};
BuildTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BuildTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query BuildTemplate($id: String!, $imageSrc: String!) {
    guide: mdx(id: { eq: $id }) {
      fields {
        slug
        timeToRead
      }
      frontmatter {
        title
        description
        date
        author
        profession
        specialization
        role
        skills
        traits
        archive
        hasBeginner
        boons {
          name
          uptime
          variant
        }
        conditions {
          name
          uptime
        }
        code
        classification
        cmGuide
      }
    }

    image: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: $imageSrc }
    ) {
      relativePath
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
      }
    }
  }
`;
